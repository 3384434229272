var axios = require("axios").default;
const baseUrl = `https://api.ebsr.in/`;
const localData = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
const token = localData.token
export default class Recipient {
    async RecipientDetail_OD(data) {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}recipientInformation/fetch_recipient_information_od/${data}`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };
    async RecipientDetail_OS(data) {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}recipientInformation/fetch_recipient_information_os/${data}`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };
    async SurgeonList() {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}users/view/hospital_surgeon`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };
   

    async RecipientDetail() {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}recipientInformation/view_all`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };
}